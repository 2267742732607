.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}
.text-center{
  text-align: center;
  margin-top: 80px;
}
.label {
  font-weight: bold;
  margin-bottom: 8px;
  margin-right: 10px;
  font-size: 20px;
  color: #264E54;
}

.dropdown {
  width: 150px;
  transition: border-color 0.2s ease-in-out;
  padding: 0.5rem;
  font-size: 14px;
  outline: none;
  background-color: #F8FCF9;
  margin-left: 50px;
  margin-top: -45px;
}
.react-select__option--is-focused,
.react-select__option--is-selected,
.dropdown-option:hover {
  background-color: #FEB52B !important; /* Use !important to override other styles */
  
}

.date-picker {
  width: 130px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}
/* Add styles for the outline around the DatePicker */
.outline-card {
  border: 2px solid #00bcd4; /* Adjust the border color and width as needed */
  border-radius: 4px;
}

/* Add styles for the selected date */
.selected-date {
  border: 2px solid #00bcd4; /* Adjust the border color and width as needed */
  border-radius: 4px;
}

/* Other styles for the DatePicker */
.custom-datepicker {
  width: 150px;
  transition: border-color 0.2s ease-in-out;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  background-color: #F8FCF9;
}
.custom-datepicker::placeholder {
  color: #555; /* Adjust the color as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 14px;
  background-color: #F8FCF9;
}

/* Add styles for the selected date text (optional) */
.custom-datepicker__input {
  color: #333; /* Adjust the color as needed */
  font-size: 14px;
}


.react-datepicker-wrapper {
  display: inline-block;
  position: relative;
}
 
.react-datepicker {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  max-height: 370px;
}

.react-datepicker__header {
  background-color: #9dafb5;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.react-datepicker__navigation--previous::before,
.react-datepicker__navigation--next::before {
  color: white;
}


.react-datepicker__day {
  padding: 8px;
  cursor: pointer;
}


.download-icon {
  position: absolute;
  top: 100px; 
  right: 50px; 
  display: flex;
  font-size: 40px;
  color: #264E54;

}

.report-title {
  text-align: center;
  margin-bottom: 30px;
}

.card-date-picker {
  border: 1px solid #F8FCF9;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  background-color: #F8FCF9;
}

.Reportcard {
  background-color: #F8FCF9;
  border: 1px solid #F8FCF9;
  padding: 8px;
}

.Reportcard-body {
  background-color: #F8FCF9;
  border: 1px solid #F8FCF9;
  padding: 8px;
}

.loading-icon {
  text-align: center;
  margin-left: 380px;
  font-size: 35px;
  color: #264E54;
}
.error-message {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.02em;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: red !important;
  background-color: transparent; 
}
@media (max-width: 768px) {
  .Reportcard-body {
    margin-top: 10px;
  }
  .download-icon {
    margin-right: 130px;
    margin-top: 400px;
}
.form-row{
  flex-direction: column;
 margin-top: 40px;
}
.react-datepicker{
  margin-left:-90px;
  width: auto;
}
.loading-icon{
  margin-top: 20px;
  margin-left: 120px;
}
.error-message{
  margin-top: 270px;
  color: red !important;
  background-color: transparent; 
}
}