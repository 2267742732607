.appuser {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;

}
 .h2heading{
  margin-top: 100px;
  text-align: center;
 }
.cardClassname{
    font-size: 30px !important;
    padding: 10px;
    }
 
.center {
  text-align: center;
  }
 
.Usercard {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 10px;
  height: 550px; 
  overflow: hidden; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff; 
 overflow-y: auto;
}
 

.Usercard h2 {
  margin-bottom: 10px;
}
 
.Usercard i {
  cursor: pointer;
  color: rgb(23, 22, 22);
  margin-left: 420px;
  font-size: 20px;
  margin-top: -50px;
}
 

.Usercard input {
  margin-bottom: 10px;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
 
.dotted-box{
  border: 2px dashed rgb(207, 205, 205);
  border-radius:5px;
  padding: 10px;
  position: relative;
  transform: 'translate(-50%, -50%)';
  text-align: 'center';
  color: '#264E5A';
margin-top: 5px;
margin-bottom: 5px;
}
    .appuserItem{
      margin-top: 10px;
    }                                                
.column-container {
  display: flex;
}
 
.column {
  width: 500px;
  margin-right: 20px;
}
 
.list {
  padding: 6px;
  border-radius: 5px;
  width: 100%;
}
 
.item {
  user-select: none;
  padding: 6px;
  background: #eeeeee;
  border: 1px solid #ddd;
  border-radius: 5px;
 text-align: left;
}

.fas fa-times{
    font-size: 100px;
}

.loading-indicator-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.loading-indicator {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media (max-width: 768px) {
  .appuser center{
    margin: auto;
    overflow: hidden;
    width: 100%;
    align-items: center;
  }
 
  .column-container{
 margin: auto;
    flex-direction: column;
    margin-top: 400px;
  }
  .column{
    width: 100%;
    margin-right: 0;
  }
  .h2heading {
    margin-top: 80px; 
  }
}

.main-container {
  overflow-y: auto; 
  max-height: 100vh; 
}
