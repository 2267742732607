.error-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.errorcard-body{
  text-align: center;
  font-size: 20px;
  color: #264E5A;
}
.errorcard {
  width: 80%; 
  max-width: 850px; 
  overflow: hidden;
  background: var(--white);
  box-shadow: 1px 3px 15px 2px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 4% 6%; 
  display: flex;
  flex-direction: column;
}

div {
  font-family: var(--regular-text) !important;
  font-weight: 400;
  letter-spacing: 0.02em;
}

.error-message {
  font-family: var(--regular-text);
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--primary);
  margin-bottom: 20px; 
}

.back-btn {
  padding: 15px 20px;
  width: 60%;
  max-width: 200px;
  font-family: var(--regular-text);
  background: transparent;
  border-radius: 57px;
  border: 1.5px solid #264E5A;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  margin: 70px auto; 
}

/* Media query for smaller screens (adjust the max-width as needed) */
@media screen and (max-width: 768px) {
  .errorcard {
    padding: 4% 8%; 
  }

  .back-btn {
    padding: 12px 16px; 
    font-size: 14px; 
    line-height: 18px; 
  }
}

/* Additional media query for screens below 75% */
@media screen and (max-width: 600px) {
  .errorcard {
    width: 75%; 
  }

  .back-btn {
    margin: 20px auto; 
  }
}
