
body {
    background-attachment: fixed;
    overflow: hidden;
}
.status-patients-container,
.location-patients-container,
.payment-patients-container {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    margin:auto;
}
.status-patients-containerweekly {
    max-width: 100%x;
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    margin:auto;
}
.status-patients-containermonthly {
    max-width: 100%;
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    margin:auto;
}

.util-text {
    max-width: 300px;
    text-align: center;
    margin: 1rem auto;
}

.dashboard-form {
    display: flex;
    justify-content: space-between;
    width: 55%;
    margin: 1.5rem auto;
}

.cardCss,
.cardCssweekly,
.cardCssMonthly {
    padding: 23px 15px;
    box-shadow: 1px 3px 15px 2px rgba(0, 0, 0, 0.1);
    margin: 1%;
    text-align: center;
    width: 100%; 
    height: auto; 
}
.download-icon-dash {
    position: fixed;
    right: 40px; 
    color: #264E5A;
    font-size: 40px;
    margin-top: -50px;
}
.text-center {
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 0px;
    margin-top:6%;

  }
 

@media only screen and (max-width: 768px) {
    .status-patients-container,
    .location-patients-container,
    .payment-patients-container,
    .status-patients-containerweekly,
    .status-patients-containermonthly {
        max-width: 100%;
        height: auto;
        margin-top: 100px;
    }

    .cardCss,
    .cardCssweekly,
    .cardCssMonthly {
        padding: 15px; 
        height: auto; 
    }
}

.chartPageCss>.container{
    width:100%;
    max-width:95%;
}



@media only screen and (min-width: 360px) and (max-width: 768px) {
    .download-icon-dash {
        top: 135px;
        right: 10px;
        color: #264E5A;
        font-size: 40px;
        height: 50px;
    }
    .text-center {
        text-align: center;
        margin-bottom: 0;
        padding-bottom: 0px;
        margin-top:20%;
    
      }
      .text-center-h2{
        margin-top: 20px;
      }
    .timespan-col,.selection-col, .btn-submit {
        margin: 14px;
    }
    .timespan-col,
    .selection-col {
        display: grid; 
        flex-flow: column wrap;
    }
      
   form.w-50 {
        margin-top: -20em;
      }

}

.btn-submit {
    padding: 17px 30px;
    width: auto;
    height: 41px;
    left: 1119px;
    top: 1187px;
    background: #FEB52B;
    border-radius: 57px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-authenticator {
    height: calc(100vh - 80px);
}

.chart-title {
    color: #264E5A;
    font-weight: bolder;
}

.spinner{
    color: #264E54;
    position: fixed;
    top: 50%;
    left: 50%;
    justify-content: center;
}

  
  form.w-50 {
    margin-top: -10em;
  }



.error-message {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: red;
    background-color: transparent; 
  }
  
