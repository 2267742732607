.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #264E5A;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar.open {
  width: 250px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: white;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #FEB52B;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

.sidebar-container {
  position: relative;
}

.openbtn {
  font-size: 25px;
  cursor: pointer;
  background-color: #264E5A;
  color: white;
  padding: 5px 10px;
  border: none;
  position: fixed;
  top: 15px;
  left: 40px; 
}

.openbtn:hover {
  background-color: #FEB52B;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.close-sidebar-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: none;
}
.sidebar:not(.open) {
  width: 0;
}

