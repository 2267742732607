body{
  overflow: auto;
}
.table-container {
  font-family: var(--regular-text) !important;
  margin-top: 40px;
  align-items: center;
  max-height: 500px; 
  overflow-y: auto;
}
.bold-header {
  font-weight: bold;
  background-color: #f0f0f0;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
  font-size: 20px;
  color: #264e5a;
}

.MuiDataGrid-root{
  background-color: white;
}
.MuiDataGrid-cellContent {
  font-size: 17px;
}
.MuiTablePagination-displayedRows{
  display: none !important;
}

.MuiDataGrid-selectedRowCount{
  display: none !important;
}
.search-bar {
  margin-right: 10px; 
  width:400px !important;
}
.MuiOutlinedInput-root {
  background-color: transparent;
}
.MuiTablePagination-actions{
  display: none !important;
}
.MuiTablePagination-root{
  display: none !important;
}
.save-button{
  justify-content: center;
  display: flex;
  margin: auto;
  padding: 10px;
}

.add-user-section {
  display: flex;
  align-items: center;
}
.addUserText {
  margin-left: 7px; 
  margin-top: 5px;
}
.addUserGlyphicon {
  margin-right: 5px; 
}
.custom-location-header {
  border-right: none !important; 
}
.search-and-add-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media (max-width: 768px) {
  .MuiDataGrid-root {
    overflow-x: auto;
    width: 300px;
  }
  .search-bar {
    width: 100%!important;
    margin: auto;
    display: flex;
    overflow: hidden;
    margin-left: auto!important;
  }
  .search-and-add-container {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
  .addUserText {
    margin-left: 7px; 
    margin-top: 5px;
  }

}
