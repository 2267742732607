.landing-submit-btn{
  padding: 21px 0px;
  width: 149px;
  height: 37px;
  background: var(--accent);
  border-radius: 57px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center !important;
  justify-content: center!important;
  margin: auto;
  margin-top:40px;
  margin-bottom: 20px;
}
.landing-close-btn{
  padding: 17px 38px;
  width: 112px;
  height: 55px;
  background: transparent;
  border-radius: 57px;
  border: 1.5px solid rgb(38, 78, 90);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}
.Practitioner-label{
  font-weight: 400;
  font-size: 25px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #264e5a;
  flex: none;
}
.success-popup {
  position: fixed;
  top: 40px; 
  right: 80px; 
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
.upload-btn {
  cursor: pointer;
}

.upload-btn-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 40px;
  border: 1px solid grey;
  border-radius: 5px;
  margin-top: 20px;
  font-family: var(--regular-text) !important;
  color: #264e5a;
  position: relative;
}

.upload-btn-content i {
  margin-right: 5px;
}

.upload-btn-content span {
  flex: 1;
  text-align: center;
}

.upload-btn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.Practitioner-dropdown-option{
  width: 200px;
}

.error-msg {
  background-color: transparent;
  border: none;
  color: red;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
h5{
  font-size: 1.25rem;
  font-weight: bold;
}