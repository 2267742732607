.header-section{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #264E5A;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

img {
  height: 55%;
}

.header-section img {
  height: 44px;
  width: auto;
}
body {
  font-family: "Lato", sans-serif;
}


#main {
  transition: margin-left 0.5s;
  padding: 96px 16px 16px;
}
