.landing-main-heading-div {
  font-size: 40px !important;
  line-height: 35px !important;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--primary);
  margin-bottom: 20px;
}
.formLabel {
  font-style: normal;
  left: 10px;
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #264e5a;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.formLabel-1{
  font-style: normal;
  left: 10px;
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #264e5a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 80px;
}

.provider-dropdown-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.dropdown-option{
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
  margin-top: 10px;
}
.react-select__option--is-focused,
.react-select__option--is-selected,
.dropdown-option:hover {
  background-color: #FEB52B !important; 
  color: black;
}

.css-qbdosj-Input {
  width: 150px;

}

.react-select__control--is-focused {
  box-shadow: none !important;
  border-color: #9dafb5 !important; 
}

.landing-button {
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-top: 20px;
}
.custom-button-container {
  text-align: left;
}

.landing-start-btn {
  padding: 21px 0px;
  width: 149px;
  height: 37px;
  background: var(--accent);
  border-radius: 57px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
  margin-top: 30px;
}
.name-input-container {
  display: flex;
  align-items: center;
}

.name-input-container input {
  margin-right: 10px;
}
.input-container {
  display: flex;
  align-items: center;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.checkbox-label {
  margin-left: 20px;
  white-space: nowrap; 
}

.checkbox-label:first-child {
  margin-left: 0; 
}
.checkbox2 {
  margin-left: 10px;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 5px; 
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
  flex-direction: column;
}
.textbox {
  width: 300px; 
}
.success-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 5px;
}


.patient-info-snack-msg {
  display: flex;
  justify-content: space-between;
  bottom: 0;
  padding: 0.5rem;
  animation: slidein 150ms linear forwards;
  margin-top: 30px;
  color: red;
  margin-left: 100px;
}

.patient-info-snack-msg-danger { background-color: rgb(243, 51, 51); color: white; }

.pointer {
  cursor: pointer !important;
}

.patient-info-snack-msg-danger button {
  background-color: transparent;
  border: none;
  color: red;
  font-size: 10px;
  cursor: pointer;
}


.landing-back-button {
  margin-left: 10px;
}


@media screen and (max-width: 1000px) {
  .form-container {
   margin-top: -80px;
  }

  .formLabel1 {
    margin-left: 10px; 
    margin-top: -45px; 
  }

}


@media  (430px <= width <= 600px){
  .form-container {
    margin-top: 30px;
  }

  .formLabel1 {
    margin-left: 10px;
    margin-top: -45px;
  }


  .slick-container {
    overflow-x: scroll;
    white-space: nowrap;
  }

  .slick-item {
    display: inline-block;
    white-space: normal;
  }
}

@media (max-width: 768px) {
.provider-dropdown-container{
 margin: auto;
flex-direction: column;
}
.formLabel1{
  margin-top: 40px;
}
.formLabel{
  margin-top: 100px;
}
.formLabel-1{
  margin-top: 20px;
  margin-left: 0%;
}
.patient-info-snack-msg{
  margin: auto;
  text-align: center;
}
}