:root {
  --primary: #264E5A;
  --light-green: #5B836D;
  --hyperlink: #0080DD;
  --accent: #FEB52B;
  --harmony: #CBD6D0;
  --harmony-bg: #F8FCF9;
  --white: #fff;
  --prompt: #9DAFB5;
  --gray: #6A6A6A;
  --dark-text: #2B2B2B;
  --black:#000000;
  --regular-text: 'Macklin Sans';
  --large-text: 'Macklin Display';
  --doc-text: 'Macklin Text';
  --arial-text: 'Arial';
  --rubik-text: 'Rubik';
  --bp-xsm: 480px;
  --bp-sm: 576px;
  --bp-md: 768px;
  --bp-lg: 992px;
  --bp-xl: 1200px;
  --bp-xxl: 1600px;
} 

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./assets/images/background-logo.png) no-repeat center bottom;  
  background-color: #F8FCF9;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: right; 
  background-size: 290px; 
  position: relative;
}

html {
  min-height: 100% ;
}

@media only screen and (min-width: 360px)and (max-width: 768px) {
  body{
      background-size: 86px;
  }
}


/* GLOBALS FOR FUTURE */

.app-index-h1, h1, .app-index-h2, h2 {
  font-family: var(--large-text) !important;
  font-weight: 700;
  letter-spacing: -0.01em;
}

h1,h2,h3{
  color:var(--primary)
}

.app-index-h1, h1 { font-size: 64px !important; line-height: 60px !important; }
.app-index-h2, h2 { font-size: 44px !important; line-height: 48px !important; }
.app-index-h3, h3 { font-size: 25px !important; line-height: 28px !important; }
.btn-disabled { cursor: not-allowed; }

.app-index-h3, h3, h4, h5, h6, p, div, article, section, span {
  font-family: var(--regular-text) !important;
  font-weight: 400;
  letter-spacing: 0.02em;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: var(--bp-sm)) {
  .app-index-h1, h1 {
      font-size: 36px !important;
      line-height: 35px !important;
  }
  .app-index-h2, h2 {
      font-size: 32px !important;
      line-height: 35px !important;
  }
  .app-index-h3, h3 {
      font-size: 21px !important;
      line-height: 25px !important;
  }
}
@media only screen and (min-width: var(--bp-sm)) {
/* Small devices (portrait tablets and large phones, 600px and up) */
}

@media only screen and (min-width: var(--bp-md)) {
/* Medium devices (landscape tablets, 768px and up) */

}
@media only screen and (min-width: var(--bp-lg)) {
/* Large devices (laptops/desktops, 992px and up) */
  .app-index-h1, h1 {
      font-size: 64px !important;
      line-height: 60px !important;
  }
  .app-index-h2, h2 {
      font-size: 44px !important;
      line-height: 48px !important;
  }
  .app-index-h3, h3 {
      font-size: 25px !important;
      line-height: 28px !important;
  } 
}
@media only screen and (min-width: var(--bp-xl)) {
/* Extra large devices (large laptops and desktops, 1200px and up) */
  .app-index-h1, h1 {
      font-size: 64px !important;
      line-height: 60px !important;
  }
  .app-index-h2, h2 {
      font-size: 44px !important;
      line-height: 48px !important;
  }
  .app-index-h3, h3 {
      font-size: 25px !important;
      line-height: 28px !important;
  }
}
@media only screen and (min-width: 360PX) and (max-width: 768px) {
    .app-index-h1{
      font-size: 32px !important;
      line-height: 35px;
      letter-spacing: -0.01em;
      color: #264E5A;
      margin: 20px auto !important;
    }
}
